<template>
  <v-container>
    <v-card class="transparent" flat>
      <v-card-title class="title font-weight-bold">
        <span class=""> 주문 일지 </span>
      </v-card-title>
      <v-card-subtitle> 주문을 관리하는 페이지입니다. </v-card-subtitle>
    </v-card>
    <v-card elevation="4" rounded="lg" class="pa-4">
      <v-card-text>
        <v-row>
          <v-col cols="7" sm="8" md="9" lg="7">
            <v-row>
              <v-col cols="12" class="pb-0">
                <span class="font-weight-bold mr-3"> 전체 통계 : </span>
                {{ result.totalSales.toLocaleString() }}원 /
                {{ result.totalCounts.toLocaleString() }}건
              </v-col>
              <v-col cols="12" class="py-0">
                <span class="font-weight-bold mr-3"> - 메이드올 : </span>
                {{ result.madeallSales.toLocaleString() }}원 /
                {{ result.madeallCounts.toLocaleString() }}건
              </v-col>
              <v-col cols="12" class="py-0">
                <span class="font-weight-bold mr-3"> - 경희대학교 : </span>
                {{ result.khuSales.toLocaleString() }}원 /
                {{ result.khuCounts.toLocaleString() }}건
              </v-col>
              <v-col cols="12" class="py-0">
                <span class="font-weight-bold mr-3"> - 더엠제이 : </span>
                {{ result.themjSales.toLocaleString() }}원 /
                {{ result.themjCounts.toLocaleString() }}건
              </v-col>
              <v-col cols="12" class="py-0">
                <span class="font-weight-bold mr-3"> - 쓰리디솔루션 : </span>
                {{ result.solutionSales.toLocaleString() }}원 /
                {{ result.solutionCounts.toLocaleString() }}건
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" sm="4" md="3" lg="5">
            <v-row>
              <v-col cols="12" lg="4">
                <v-select
                  v-model="selectedYear"
                  :items="years"
                  label="Year"
                  item-value="value"
                  item-text="label"
                  outlined
                  hide-details
                  dense
                  @change="getMilliseconds"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-select
                  v-model="selectedMonth"
                  :items="months"
                  label="Month"
                  item-value="value"
                  item-text="label"
                  outlined
                  hide-details
                  dense
                  @change="getMilliseconds"
                />
              </v-col>
              <v-col cols="12" lg="4">
                <v-select
                  v-model="selectedSpace"
                  :items="workspace"
                  label="작업 장소"
                  outlined
                  hide-details
                  dense
                  @change="getOrderData"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :loading="fetchLoading"
        :items="result.items"
        :items-per-page="500"
        hide-default-footer
        @click:row="openDialog"
        class="transparent my-table"
      >
        <template v-slot:[`item.paidAt`]="{ item }">
          <span>{{ item.paidAt | moment("MM/DD HH:mm") }}</span>
        </template>
        <template v-slot:[`item.totalPrice`]="{ item }">
          <span>{{ parseInt(item.totalPrice).toLocaleString() }}원</span>
        </template>
      </v-data-table>
    </v-card>
    <admin-order-dialog
      :dialog="orderDialog"
      @saveOrder="saveOrder"
      @closeDialog="closeDialog"
      :data="dialogData"
    />
  </v-container>
</template>
<style>
.my-table td {
  height: 25px !important;
}
</style>
<script>
import setMeta from "@/utils/setMeta";
import constants from "@/utils/constants";
import _ from "lodash";
import AdminOrderDialog from "../../components/Dialog/Admin/Order/AdminOrderDialog.vue";
import moment from "moment";

export default {
  components: {
    AdminOrderDialog,
  },
  name: "OrderList",
  data() {
    return {
      selectedYear: moment().year(),
      selectedMonth: moment().month() + 1,
      years: [],
      months: [],
      selectedSpace: "전체",
      workspace: [
        "전체",
        "메이드올 대구지사",
        "경희대학교 국제캠퍼스",
        "더엠제이",
        "쓰리디솔루션",
      ],
      option: {
        workspace: "전체",
        period: 0,
      },
      fetchLoading: true,
      headers: [
        { text: "이름", value: "displayName", align: "center", width: "90px" },
        { text: "상태", value: "status", align: "center", width: "90px" },
        { text: "제목", value: "purpose", align: "center" },
        { text: "작업장", value: "workspace", align: "center" },
        {
          text: "결제일시",
          value: "paidAt",
          align: "center",
        },
        {
          text: "결제금액",
          value: "totalPrice",
          align: "center",
        },
      ],
      result: {
        items: [],
        totalSales: 0,
        totalCounts: 0,
        madeallSales: 0,
        madeallCounts: 0,
        khuSales: 0,
        khuCounts: 0,
        themjSales: 0,
        themjCounts: 0,
        solutionSales: 0,
        solutionCounts: 0,
      },

      orderStatusItem: constants.orderStatusItem,

      orderDialog: false,
      dialogData: {},
    };
  },
  created() {
    setMeta({
      title: "주문 일지",
      description: "메이드올 전체 시제품 제조 결제 일지",
    });
  },
  mounted() {
    this.getYearOptions();
    this.getMonthOptions();
    this.getMilliseconds();
  },
  methods: {
    getYearOptions() {
      const currentYear = moment().year();
      for (let year = currentYear; year >= currentYear - 10; year--) {
        this.years.push({ value: year, label: year + "년" });
      }
    },
    getMonthOptions() {
      for (let month = 1; month <= 12; month++) {
        const label = month.toString() + "월"; // Use the month number as the label
        this.months.push({ value: month, label: label });
      }
    },
    getMilliseconds() {
      if (this.selectedYear && this.selectedMonth) {
        const month = moment(
          `${this.selectedYear}-${this.selectedMonth}`,
          "YYYY-M"
        ).format("YYYYMM");
        const milliseconds = moment(month, "YYYYMM").valueOf();
        this.option.period = milliseconds;
        this.getOrderData();
      }
    },
    getOrderData() {
      this.fetchLoading = true;
      this.option.workspace = this.selectedSpace;
      this.$axios
        .patch("admin/getOrders", this.option)
        .then(({ data }) => {
          this.result = data;
          console.log(this.result);
        })
        .catch((e) => {
          this.$toasted.global.error(e.message);
        })
        .finally(() => {
          this.fetchLoading = false;
        });
    },
    getStatusData(statusLevel) {
      return this.orderStatusItem[statusLevel];
    },
    reload() {
      this.getOrderData();
    },
    async saveOrder(order) {
      if (order.status < 3) {
        if (order.confirmed) {
          order.confirmed = false;
          order.confirmedAt = null;
        }
      } else {
        if (!order.confirmed) {
          order.confirmed = true;
          order.confirmedAt = new Date();
        }
      }
      await this.$axios.put("admin/updateOrder/" + order.id, {
        admin: this.$store.getters["auth/user/GET_USER"].displayName,
        item: order,
      });
      this.getOrderData();
    },
    openDialog(value) {
      this.dialogData = _.merge(
        _.cloneDeep(constants.orderFormInterface),
        value
      );
      this.orderDialog = true;
    },
    closeDialog() {
      this.dialogData = _.cloneDeep(constants.orderFormInterface);
      this.orderDialog = false;
      this.getOrderData();
    },
  },
};
</script>
